import React from "react";
import { Container } from "react-bootstrap";

function News2() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        February 7, 2022
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials Emerges from Stealth to Launch Next-Generation Cathode
        Materials
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        PITTSBURGH, February 7, 2023 /Business Wire — Stratus Materials Inc., a
        developer and manufacturer of next-generation cathode active materials
        for lithium-ion batteries, came out of stealth today. The company,
        previously operating as 33 Tech Inc., was founded in early 2022 to
        commercialize a set of highly promising, manganese-rich, cobalt-free
        cathode innovations by a team led by Dr. Jay Whitacre, a Carnegie Mellon
        University professor and serial entrepreneur.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        For years, the lithium-ion battery industry has been seeking the
        performance and cost advantages of a high-manganese cathode chemistry,
        but these cathodes have faced persistent challenges with stability and
        cycling. Utilizing proprietary formulations and production processes,
        the Stratus Materials team has developed manganese-rich, zero-cobalt
        cathode materials that deliver outstanding functionality, energy
        density, stability, low cost, and input materials availability,
        overcoming the challenges that have hindered high-manganese systems to
        date. The company is focused on scaling the production of these novel
        cathode materials, which are being specifically targeted at light- and
        medium-duty electric vehicles as well as other applications with similar
        performance requirements.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Jay Whitacre, CEO & CTO of Stratus Materials, said: “Our innovative
        processes and materials are designed to provide the lithium-ion battery
        industry with cathode offerings that outperform best-in-class NMC
        cathode formulations on virtually every dimension, without relying on
        cobalt and with significantly less nickel and lithium per kilowatt-hour.
        We are extremely excited about engaging more broadly with potential
        customers and partners.”
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        In mid-2022, the company completed a $12M Series A financing led by
        Breakthrough Energy Ventures (BEV) with participation from DNS Capital.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        “Energy density, cost, materials availability, and safety are critical
        factors for a battery technology to enable full EV adoption and
        production,” said Carmichael Roberts, Breakthrough Energy Ventures. “The
        Stratus Materials team has developed a set of cathode active materials
        that address and optimize each of these parameters, enabling EVs to
        scale to new levels.”
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Stratus Materials is currently producing its proprietary cathode active
        material at an early pilot-scale and is providing samples to a small
        group of potential initial customers. Representatives of the company
        will be attending the International Battery Seminar & Exhibit next month
        in Orlando, FL. Please contact the company at info@stratusmaterials.com
        for more information or to inquire about a meeting.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>ABOUT STRATUS MATERIALS</strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Stratus Materials (www.stratusmaterials.com) is a U.S. based developer
        and manufacturer of next-generation cathode active materials for
        lithium-ion batteries. Our mission is to offer a highly compelling
        combination of performance, safety, cost, and environmental impact to
        leading battery manufacturers and their downstream OEM customers.
        Stratus is primarily focused on cathode solutions for light- and
        medium-duty electric vehicles and other applications with similar
        requirements.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>ABOUT BREAKTHROUGH ENERGY VENTURES</strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Founded by Bill Gates and backed by many of the world’s top business
        leaders, BEV has raised more than $2 billion in committed capital to
        support cutting-edge companies that are leading the world to net-zero
        emissions. BEV is a purpose-built investment firm that is seeking to
        invest, launch and scale global companies that will eliminate GHG
        emissions throughout the economy as soon as possible. BEV seeks true
        breakthroughs and is committed to supporting these entrepreneurs and
        companies by bringing to bear a unique combination of technical,
        operational, market and policy expertise. BEV is a part of Breakthrough
        Energy, a network of investment vehicles, philanthropic programs, policy
        advocacy and other activities committed to scaling the technologies we
        need to reach net-zero emissions by 2050. Visit{" "}
        <a
          href="http://www.breakthroughenergy.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.breakthroughenergy.org
        </a>
        &nbsp; to learn more.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>ABOUT DNS CAPITAL, LLC</strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        DNS Capital is the investment office and internal merchant bank for the
        business interests of Gigi Pritzker, Michael Pucker, and their
        daughters. DNS Capital takes a long-term and flexible approach to
        investing by building relationships with business owners, management
        teams, and entrepreneurs to align interests, evolve businesses, and
        create lasting value with a sense of purpose and a sense of humor. To
        learn more about DNS Capital, please visit &nbsp;
        <a
          href="http://www.dnscap.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.dnscap.com
        </a>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong></strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}></p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong></strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}></p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News2;
