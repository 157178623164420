import React from "react";

function LogoEconomic({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{ display: "block", maxWidth: "122px", margin: "0 auto" }}
    >
      <path
        d="M24 29.81v2.94m0-18.8v3.15m0 6.35c1.88 0 3.4 1.42 3.4 3.18 0 4.2-6.81 4.2-6.81 0M24 23.45c-4.5 0-4.5-6.35 0-6.35 1.88 0 3.4 1.42 3.4 3.18"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
      <path
        d="M36.35 32.22C42.46 22.59 35.59 9.77 24 9.77S5.54 22.59 11.66 32.22"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
      <path
        d="m41.39 31.4 1.86 6.83c-11.01-1.82-27.49-1.82-38.5 0l1.86-6.21"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
    </svg>
  );
}

export default LogoEconomic;
