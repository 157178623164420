import React from "react";
import "./About.css";
import MarketsHeaderImg from "../assets/markets-header.jpg";
import marketImg1 from "../assets/markets-1.png";
import marketImg2 from "../assets/markets-2.png";
import marketImg3 from "../assets/markets-3.JPG";
import PageHeader from "../components/PageHeader";
import TextPic from "../components/TextPic";
import PicText from "../components/PicText";

const PageTitle = "Advanced Cathodes to Meet Market Needs";
const SubTitle =
  "Sampling and Testing LXMO™ with major players in the Li-Ion supply chain ";
const SubContent = `
        Stratus has been sampling and validating its advanced LXMO cathode
        materials with external end-users and partners since the first quarter
        of 2023. This broad set of industry players evaluating LXMO is expanding
        and includes many leading EV OEMs, major Li-Ion cell and pack producers,
        as well as established CAM manufacturers.`;

const title1 = "Full Format Validation";
const content1 = `
        To date, LXMO has been built and evaluated in pouch cell formats up to 6
        Ah. In the second half of 2024, the focus shifts to constructing and
        testing larger pouch cells with capacities of 20 Ah and higher. These
        tests aim to assess LXMO's performance and scalability for potential use
        in high-capacity energy storage systems.  
        `;
const title2 = "LXMO Supply";
const content2 = `Since early 2023, Stratus has been producing its novel cathode 
        materials on a 1-ton per year pre-pilot production line in Pittsburgh.  
        We are currently in the process of building a new, 30+ ton per year pilot 
        line that will be operational in early 2025.  This additional capacity 
        will provide more LXMO material for external sampling and also validate 
        the scalability of the Stratus manufacturing processes.`;

const title3 = "Learn More";
const content3 = `If your company is interested in learning more about Stratus’s 
        LXMO cathode materials, please contact us at info@stratusmaterials.com`;

function Markets() {
  return (
    <div>
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={MarketsHeaderImg}
      />

      <TextPic
        title={title1}
        content={content1}
        img={marketImg1}
        height="450px"
      />

      <PicText
        title={title2}
        content={content2}
        img={marketImg2}
        height="500px"
      />

      <div
        className="container my-5"
        style={{ backgroundColor: "#EDEEF0", padding: "30px" }}
      >
        <div className="row">
          <div
            className="col-lg-6 col-md-12 d-flex flex-column justify-content-center"
            style={{ height: "auto" }} // Make height auto for smaller screens
          >
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "2rem", // Default for large screens
                fontFamily: "'Montserrat'",
                fontWeight: "800",
                marginBottom: "1.5rem", // Reduced margin for better spacing
                marginTop: "1.5rem",
              }}
            >
              {title3}
            </p>
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "1.4rem",
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              If your company is interested in exploring Stratus’s LXMO cathode
              materials and how they can benefit your business needs, please
              contact us at
              <br />
              <br />
              <a href="mailto:info@stratusmaterials.com">
                info@stratusmaterials.com
              </a>
            </p>
          </div>

          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img
              src={marketImg3}
              alt="img"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Markets;
