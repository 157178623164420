import React from "react";
import { Container } from "react-bootstrap";

function News6() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        June 25, 2024
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials Announces Achievement of Key Performance Milestone for
        its LXMO™ Li-ion Battery Cathode Active Material
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        PITTSBURGH, June 25, 2024 /PRNewswire/ — Stratus Materials Inc., a
        company focused on the development and commercialization of advanced
        cathode active materials (CAMs) for lithium-ion batteries, today
        announced that its first-generation LXMO CAM material has achieved a
        significant milestone associated with cycling durability in electric
        vehicle applications.
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        This month, LXMO-containing pouch cells surpassed 1,000 full
        depth-of-discharge cycles while maintaining greater than 80% of their
        initial capacity, a common goal across the EV battery industry. The 3rd
        party-built cells used standard cell and electrode designs, employing a
        natural graphite anode and an electrolyte of standard industry
        components, to work in conjunction with the Stratus cathode material.
        The cells in the test group maintained 82-86% of their initial capacity
        and are projected to exceed 1,200 cycles before reaching 80% of their
        initial capacities. At the same 1,000-cycle point, the average discharge
        voltage of the cells maintained approximately 97%, dropping only 0.1 V
        from their initial values. The excellent capacity and voltage stability
        of the cells surpass the energy retention of many commercial materials
        in use today.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Jay Whitacre, CEO & CTO of Stratus Materials (and Professor at Carnegie
        Mellon University), said: “These cells have been cycling for the last 10
        months and clearly demonstrate that our proprietary materials are able
        to meet EV industry durability needs. To our knowledge this is the first
        time such minimal loss in cell voltage has been documented for this
        class of materials. The Stratus team looks forward to building upon
        these strong technical results as we improve, commercialize, and scale
        production in the coming quarters.”
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Stratus products are in the lithium-rich, manganese-rich (LMR) category
        of cathode active materials. LMR cathodes have garnered significant
        interest in the Li-ion battery and EV industries due to several highly
        promising attributes including high energy density, low cost, and high
        safety. However, despite being in development around the world for over
        two decades, LMRs have not yet been commercially deployed because of
        several persistent technical challenges – primarily the voltage and
        capacity instability issues that Stratus Materials has largely overcome,
        as demonstrated by its latest results. With its continued technological
        progress, Stratus Materials is focused on enabling the first fully
        functional, LMR-class CAMs that are suitable for full-scale, commercial
        use.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Scott Pearson, President & CCO, added: “We are thrilled to show that our
        first-generation LXMO material can exceed this EV cell durability target
        while also delivering a set of highly compelling advantages versus
        incumbent CAMs. We expect that this proof point will be one of many that
        we will be offering to our industry partners and customers.”
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Stratus is currently producing materials on its existing pre-pilot
        production line in Pittsburgh, Pennsylvania and has ongoing sampling
        efforts underway with many of the leading companies in the EV and EV
        battery industries. As previously announced, the company will be
        increasing its production capabilities from pre-pilot to pilot scale
        later in 2024.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>ABOUT STRATUS MATERIALS</strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <a
          href="https://www.stratusmaterials.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stratus Materials
        </a>
        is a U.S. based company focused on the development and commercialization
        of next-generation cathode active materials for lithium-ion batteries.
        Our mission is to disrupt the CAM supply chain by offering materials
        with a game-changing combination of performance, safety, and cost.
        Stratus is primarily focused on cathode solutions for light- and
        medium-duty electric vehicles and other applications with similar
        requirements.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Media Contact:
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Robyn Kennedy DeSocio
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Email: marketing@stratusmaterials.com
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News6;
