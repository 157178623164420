import React from "react";
import { Card } from "react-bootstrap";

function NewsItem({ title, date, news_id, coverImage }) {
  return (
    <a href={`/news/${news_id}`} style={{ textDecoration: "none" }}>
      <Card
        className="mb-4"
        style={{
          // margin: "10px",
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#c0c8cf",
          flexWrap: "wrap",
        }}
      >
        <div className="d-none d-md-block" style={{ flex: 1 }}>
          {/* The image will be hidden on screens smaller than 'md' */}
          <Card.Img
            src={coverImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <Card.Body
          style={{
            flex: 2,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          <p
            style={{
              color: "#00ade6",
              fontSize: "1.25rem",
              marginBottom: "20px",
            }}
          >
            {date}
          </p>
          <p
            style={{
              color: "#000045",
              fontSize: "1.5rem",
              fontWeight: "bold",
              fontFamily: "Sans-serif",
              marginBottom: "20px",
            }}
          >
            {title}
          </p>
        </Card.Body>
      </Card>
    </a>
  );
}

export default NewsItem;
