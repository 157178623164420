import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";

const WhitePaper = () => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    position: "",
    email: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://stratusmaterials.com/api/send-email", {
        ...formData,
        formtype: "whitepaper",
      });
      setSubmitted(true);
      setError(null);
    } catch (err) {
      setError("Failed to submit the form. Please try again.");
      console.error(err);
    }
  };

  return (
    <Container fluid>
      <Row
        className="justify-content-center"
        style={{ backgroundColor: "#003561" }}
      >
        <Col md={10} lg={8} className="my-4">
          <h2 className="text-white">White Paper Download</h2>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "50px" }}>
        <Col md={8} lg={5}>
          <h4
            style={{
              color: "#344D76",
              fontFamily: "Montserrat",
              fontWeight: "800"
            }}
          >
            Modified Lithium-Rich/Manganese-Rich Cathode Active Materials: The
            Performance and Benefits of LXMO™
          </h4>
          <p
            style={{
              fontSize: "20px",
              color: "#344D76",
              fontFamily: "Sans-serif",
            }}
            className="my-4"
          >
            Stratus has created a downloadable white paper that provides a
            data-driven summary of the unique and valuable combination of
            performance, safety, and cost that our LXMO CAMs can deliver to the
            lithium-ion battery industry. In summary, this whitepaper describes
            how LXMO delivers battery performance that exceeds high-nickel NMC
            CAMs while providing the high safety and low cost per unit energy of
            LFP CAMs. This combination of attributes is particularly attractive
            to and enabling for the electric vehicle industry.
          </p>

          <p style={{
              color: "#344D76",
              fontFamily: "Sans-serif",
            }}>
            Please fill out the form to receive the white paper.
          </p>
          {submitted ? (
            <h3 style={{ color: "green" }}>
              Thank you! The white paper has been sent to your email.
            </h3>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="company">
                <Form.Control
                  type="text"
                  placeholder="Company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="position">
                <Form.Control
                  type="text"
                  placeholder="Position"
                  value={formData.position}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              {error && <p style={{ color: "red" }}>{error}</p>}

              <Button
                type="submit"
                style={{
                  backgroundColor: "#344D76",
                  borderColor: "#344D76",
                  borderRadius: "0",
                  marginTop: "20px",
                }}
              >
                <span
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  SUBMIT
                </span>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default WhitePaper;
