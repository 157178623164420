import React, { useState } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import JayImage from "../assets/profile/Jay.jpg";
import ScottImage from "../assets/profile/Scott.jpg";
import WeiImage from "../assets/profile/Wei.jpg";
import JaredImage from "../assets/profile/Jared.jpg";
import AndreaImage from "../assets/profile/Andrea.jpg";
import IanImage from "../assets/profile/Ian.jpg";
import WilliamImage from "../assets/profile/William.jpg";
import MarkImage from "../assets/profile/Mark.png";
import AlanImage from "../assets/profile/Alan_Nelson.jpg";
import SeparationBar from "./SeparationBar";

// Management and advisor information, including detailed descriptions for the modal
const management = [
  {
    name: "Jay Whitacre",
    title: "CEO | CTO | Founder",
    image: JayImage,
    description:
      "Jay is a material scientist and physicist with over 25 years of technical, business, and academic experience. His career has been focused on understanding and developing electrochemical materials and systems with a focus on lithium-ion batteries, energy policy, and innovation theory. He is a Professor at Carnegie Mellon University in the Materials Science and Engineering & Engineering and Public Policy departments, holds the Trustee Chair in Energy, and is currently on academic leave (after serving as the director of the Scott Institute for Energy Innovation for 6 years).  Whitacre developed a novel aqueous battery chemistry that was manufactured and sold by Aquion Energy, a company he founded in 2008 that produced and shipped over 30 MWh of energy storage worldwide. Earlier in his career, he was a Senior Member of the Technical Staff at the Jet Propulsion Laboratory, where he worked as a battery technologist, system engineer, and mission cognizant engineer. Jay holds a BA in Physics from Oberlin College as well as a Ph.D. in Materials Science and Engineering from the University of Michigan.",
  },
  {
    name: "Scott Pearson",
    title: "President | CCO | Co-Founder",
    image: ScottImage,
    description:
      "Scott is a seasoned executive with over 30 years of experience in multiple industries including energy storage, uninterruptible power systems, fuel cells, electric transportation, electronics assembly equipment and materials, digital imaging, defense systems and additive manufacturing. In his career, he has held the role of CEO and/or President six times in addition to prior functional roles in product development, product management, operations/manufacturing, business development, and corporate strategy. He holds an M.B.A. from MIT’s Sloan School of Management, an M.S. in Mechanical Engineering from MIT, and a B.S. in Mechanical Engineering from the University of Massachusetts.",
  },
  {
    name: "Wei Wu",
    title: "VP Equipment & Materials | Co-Founder",
    image: WeiImage,
    description:
      "Wei is a well-rounded technical leader with over 12 years of experience in material science, engineering, and supply chain management for materials in energy storage applications.  He has held Director and Senior Manager roles in research and development, product development, and supply chain management at Aquion Energy and Amazon Inc.  He holds a Ph.D. in Material Science and Engineering from CMU, an M.B.A. from CMU’s Tepper School of Management, and a B.S. in Electronic Science and Engineering from Southeast University in China.",
  },
  {
    name: "Jared Mitchell",
    title: "Director, Process & Facilities | Co-Founder",
    image: JaredImage,
    description:
      "Before Stratus, Jared spent ten years at Carnegie Mellon University and Aquion Energy, Inc. focusing on materials and process innovation for advanced batteries and systems.  Prior to that, he worked for ABB and Keystone Diesel in their electric and diesel power solutions groups, respectively.  Jared’s blend of knowledge, persistence, curiosity, creativity, instinct, and hard work is truly unique and extremely valuable to Stratus.",
  },
  {
    name: "Andrea Smith",
    title: "Director, Quality",
    image: AndreaImage,
    description:
      "Andrea has 20 years of experience in process engineering, quality, and continuous improvement.  She has held roles focused on ISO9001 compliance, manufacturing quality and process control, supplier quality management, product development quality assurance, and lean six sigma problem solving in a variety of industries including energy storage, chemical coatings, food, glass and electro-mechanical devices.  Andrea has a BS in mechanical engineering from Case Western Reserve University and an MS in statistics from Penn State University.",
  },
  {
    name: "Ian Matts",
    title: "Director, Product & Technical Programs",
    image: IanImage,
    description:
      "Ian is a committed technology developer with over 10 years of lithium-ion battery experience.  Over that time, he has held diverse roles, ranging from fundamental materials research and product development to program management and business development.  His primary focus is on cathode active materials, but he has additional expertise in battery formulation optimization, electrode design, and cell characterization and testing.  Ian has B.S. and Ph.D degrees in Materials Science and Engineering, both from MIT.",
  },
];

const advisors = [
  {
    name: "William West",
    title: "Senior Advisor",
    image: WilliamImage,
    description:
      "Dr. Will West is a Principal Technologist and Group Supervisor of JPL’s Electrochemical Research, Technology, and Engineering Group. He has worked at JPL for 24 years as Principal Investigator, Co-Investigator, Task Manager, and Cognizant Engineer for numerous research and technology development and flight programs related to electrochemistry and energy storage/conversion. He received his B.S.E. degree in Chemical Engineering, and M.S. and Ph.D. degrees in Materials Science Engineering. He has worked as Prototyping Co-Lead at Caltech’s Joint Center for Artificial Photosynthesis, consultant for numerous research and development firms, expert witness for the U. S. Department of Justice, lecturer at California State Polytechnic University-Pomona, and as Associate Professor at Nagoya University (Japan). He holds 37 U.S. and international patents, is author/co-author on approximately 75 journal articles and 110 conference presentations, and co-edited/co-authored the Handbook of Solid-State Batteries.",
  },
  {
    name: "Mark Verbrugge",
    title: "Senior Advisor",
    image: MarkImage,
    description:
      "Mark received his PhD in Chemical Engineering from the College of Chemistry at the University of California (Berkeley) and an MBA from MIT. He retired from GM in 2023 after 37 years with the company, 28 of which were in various executive roles, including Chief Engineer for Energy Management Systems and Director for chemistry and materials R&D. He has received many international awards and is a Fellow of the Electrochemical Society and a Member of the National Academy of Engineering. Mark has about 200 patents, patents pending, and trade secrets and about 200 publications.",
  },
  {
    name: "Alan Nelson",
    title: "Senior Advisor",
    image: AlanImage,
    description:
      "Alan E. Nelson is the Vice President of Technology at SLB, and previously was Senior Vice President of Battery Materials for Redwood Materials, Chief Technology Officer for Abu Dhabi National Oil Co. (ADNOC), Chief Technology Officer and CEO of New Business Development for Johnson Matthey, Global R&D Director for The Dow Chemical Co., and Associate Professor of Chemical Engineering at the Univ. of Alberta. Alan has led the development and commercialization of environmentally sustainable technologies, including lithium-ion batteries, fuel cells, carbon capture and utilization, and hydrogen production. Alan is the President of the American Institute of Chemical Engineers (AIChE), a Professional Engineer in Alberta, Canada, a Chartered Engineer in the U.K., a Fellow of AIChE, and a Fellow of the Institution of Chemical Engineers (IChemE). He received a BS from Univ. of Minnesota Duluth and PhD from Michigan Technological Univ., both in chemical engineering.",
  },
];
const TeamSection = () => {
  // State to handle modal visibility and selected member
  const [selectedMember, setSelectedMember] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Function to handle card click
  const handleCardClick = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null);
  };

  const profileMaskStyle = {
    background: `linear-gradient(
    to top,
    rgba(0,0,0,0) 3%,
    rgba(0,0,0,0.3) 3% 17%,
    rgba(0,0,0,0) 17% 40%,
    rgba(0,0,0,0) 40% 60%,
    rgba(0,0,0,0) 60% 80%,
    rgba(0,0,0,0) 80%
  )`,
  };

  return (
    <div style={{ backgroundColor: "#003561" }}>
      <Container style={{ backgroundColor: "#003561", padding: "2rem 0" }}>
        <h1
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "800",
          }}
          className="my-3 mx-4"
        >
          Management
        </h1>
        <div
          className=" mx-4"
          style={{
            height: "10px",
            width: "300px",
            backgroundColor: "#00ade6",
            marginBottom: "20px",
          }}
        ></div>
        <Row xs={1} sm={2} md={3} className="g-4 my-3 mx-3">
          {management.map((member, index) => (
            <Col key={index}>
              <Card
                style={{
                  backgroundColor: "#6c757d",
                  height: "100%",
                  position: "relative",
                }}
                onClick={() => handleCardClick(member)}
              >
                {member.image ? (
                  <Card.Img
                    src={member.image}
                    alt={member.name}
                    style={{ height: "400px", objectFit: "cover" }}
                  />
                ) : (
                  <div style={{ height: "100%", minHeight: "200px" }} /> // Placeholder for cards without images
                )}
                <Card.ImgOverlay
                  className="d-flex flex-column justify-content-end"
                  style={profileMaskStyle}
                >
                  <Card.Title className="text-white mb-0">
                    {member.name}
                  </Card.Title>
                  <Card.Text className="text-white">{member.title}</Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <SeparationBar height="100px" color="white" />
      <Container style={{ backgroundColor: "#003561", padding: "2rem 0" }}>
        <h1
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "800",
          }}
          className="my-3 mx-4"
        >
          Advisors
        </h1>
        <div
          className=" mx-4"
          style={{
            height: "10px",
            width: "300px",
            backgroundColor: "#00ade6",
            marginBottom: "20px",
          }}
        ></div>

        <Row xs={1} sm={2} md={3} className="g-4 my-3 mx-3">
          {advisors.map((member, index) => (
            <Col key={index}>
              <Card
                style={{
                  backgroundColor: "#6c757d",
                  height: "100%",
                  position: "relative",
                }}
                onClick={() => handleCardClick(member)}
              >
                {member.image ? (
                  <Card.Img
                    src={member.image}
                    alt={member.name}
                    style={{ height: "400px", objectFit: "cover" }}
                  />
                ) : (
                  <div style={{ height: "100%", minHeight: "200px" }} /> // Placeholder for cards without images
                )}
                <Card.ImgOverlay
                  className="d-flex flex-column justify-content-end"
                  style={profileMaskStyle}
                >
                  <Card.Title className="text-white mb-0">
                    {member.name}
                  </Card.Title>
                  <Card.Text className="text-white">{member.title}</Card.Text>
                </Card.ImgOverlay>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal for showing member details */}
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header
          closeButton
          style={{
            paddingLeft: "3rem",
            paddingRight: "3rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Modal.Title style={{ color: "#003561" }}>
            {selectedMember?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingLeft: "3rem",
            paddingRight: "3rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <h5>{selectedMember?.title}</h5>
          <p>{selectedMember?.description}</p>
        </Modal.Body>
      </Modal>

      {/* Custom CSS for Modal */}
    </div>
  );
};

export default TeamSection;
