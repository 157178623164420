import React, { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import StratusLogo from "./logo/LogoStratus";

const NavbarComponent = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const location = useLocation();

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const navItems = [
    { href: "/about", label: "About" },
    { href: "/team", label: "Team" },
    { href: "/technology", label: "Technology" },
    { href: "/markets", label: "Users" },
    { href: "/careers", label: "Careers" },
    { href: "/news", label: "News" },
    { href: "/contact", label: "Contact" },
  ];

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>
          <Link to="/" className="navbar-brand fw-semibold">
            <StratusLogo fontColor="#003561" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto justify-content-end w-100  justify-content-lg-end">
            {navItems.map((item, index) => (
              <Nav.Link
                key={index}
                href={item.href}
                className="text-uppercase text-center"
                onMouseEnter={() => handleMouseEnter(item.label)}
                onMouseLeave={handleMouseLeave}
              >
                <p
                  style={{
                    color: "#003561",
                    fontSize: "19px",
                    fontWeight:
                      hoveredItem === item.label ||
                      location.pathname === item.href
                        ? "bold"
                        : "normal",
                    transition: "font-weight 0.3s",
                  }}
                >
                  {item.label}
                </p>
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
