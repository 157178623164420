import React from "react";
import "./Technology.css";
import { Container, Row, Col } from "react-bootstrap";
import techImg1 from "../assets/technology-1.png";
import techImg2 from "../assets/technology-2.jpg";
import techImg3 from "../assets/technology-3.JPG";
import stratusLXMOImg from "../assets/StratusLXMO.jpeg";
import TechnologyHeaderImg from "../assets/technology-header.jpg";
import PageHeader from "../components/PageHeader";
import PicText from "../components/PicText";
import TextPic from "../components/TextPic";

function Technology() {
  const PageTitle = "Game-Changing CAM Technology";
  const SubTitle =
    "LXMO™ - Superior CAM Products Enabled by Innovative Technology ";
  const SubContent = `
        Stratus Materials develops and produces novel, manganese-rich cathode
        active materials that are 100% cobalt-free. Our team has identified
        unique and proprietary formulations and processing methods to achieve
        outstanding electrochemical performance, energy density, cycling
        durability, and safety at very low costs per kWh. Stratus LXMO cathode
        active materials are designed to be superior to industry-standard high
        nickel NMC and LFP for light- to medium-duty EVs, energy storage, and
        other similar applications.
        `;
  const SubContent2 = `
        Stratus is highly focused on developing and globally protecting the
        intellectual property behind its LXMO CAM materials and manufacturing
        processes, with a broad portfolio of trade secrets in place and multiple
        patents pending.
        `;

  const title1 = "Superior Pack Energy Density";
  const title2 = "LXMO-1 Attributes (currently sampling): ";
  const title3 = "Drop-in Solution";
  const content3 = `Stratus’s LXMO cathode materials are designed to be compatible with the
        current lithium-ion battery industry. LXMO can be
        processed in the same manner as NMC CAMs in the slurry, electrode, and
        cell making processes, requiring no additional effort or costs to
        produce high-quality batteries. In addition, Stratus produces its LXMO CAMs with most
        of the same pieces of equipment used for manufacturing NMC CAMs, which
        allows for rapid scale-up of LXMO production.`;

  const liStyle = {
    color: "#003561",
    fontSize: "1.4rem",
    fontFamily: "Montserrat",
    fontWeight: "400",
    paddingLeft: "1rem",
  };
  const liStyle2 = {
    color: "#003561",
    fontSize: "1.4rem",
    fontFamily: "Montserrat",
    fontWeight: "400",
    paddingLeft: "1rem",
    marginTop: "0.5rem",
  };

  return (
    <div className="technology-page">
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={TechnologyHeaderImg}
      />

      <div className="container">
        <Row>
          <Col md={12}>
            <p
              className="display-6 fw lh-sm"
              style={{
                color: "#003561",
                fontSize: "1.4rem", // Reduced font size for better readability on small screens
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              {SubContent2}
            </p>
          </Col>
        </Row>
      </div>
      <br />
      <div style={{ backgroundColor: "#003561" }}>
        <br /> <br />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={stratusLXMOImg}
            alt="Logo"
            style={{ width: "80%", border: "none" }}
          />
        </Container>
        <br /> <br />
      </div>
      <div
        className="container my-5"
        style={{ backgroundColor: "#EDEEF0", padding: "30px" }}
      >
        <div className="row">
          <div
            className="col-lg-6 col-md-12 d-flex flex-column justify-content-center"
            style={{ height: "auto" }} // Make height auto for smaller screens
          >
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "2rem", // Default for large screens
                fontFamily: "'Montserrat'",
                fontWeight: "800",
                marginBottom: "1.5rem", // Reduced margin for better spacing
                marginTop: "1.5rem",
              }}
            >
              {title1}
            </p>
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "1.4rem", // Reduced font size for better readability on small screens
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              LXMO’s unique combination of high energy density and high safety
              can deliver EV pack energy densities up to 2X that of high nickel
              NMC and LFP, enabling:{" "}
            </p>
            <p
              className=" display-6 fw lh-sm mx-3"
            >
              <ul>
                <li style={liStyle}>
                  Up to 2X driving range in the same pack volume
                </li>
                <li style={liStyle}>
                  The same or similar driving range in a significantly smaller,
                  less expensive pack
                </li>
              </ul>
            </p>
          </div>

          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img
              src={techImg1}
              alt="img"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>

      <div
        className="container my-5"
        style={{ backgroundColor: "#EDEEF0", padding: "30px" }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img
              src={techImg2}
              alt="img"
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          <div
            className="col-lg-6 col-md-12 d-flex flex-column justify-content-center"
            style={{ height: "auto" }} // Make height auto for smaller screens
          >
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "2rem", // Default for large screens
                fontFamily: "'Montserrat'",
                fontWeight: "800",
                marginBottom: "1.5rem", // Reduced margin for better spacing
                marginTop: "1.5rem",
              }}
            >
              {title2}
            </p>
            <p
              className=" display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "1.5rem",
                fontFamily: "Montserrat",
              }}
            >
              <ul>
                <li style={liStyle2}>Specific Capacity, reversible > 235 mAh/g</li>
                <li style={liStyle2}>Specific Energy > 850 Wh/kg</li>
                <li style={liStyle2}>
                  Cycle Life – Capacity > 80% retention at 1,000 cycles
                </li>
                <li style={liStyle2}>
                  Cycle Life – Voltage > 97% retention at 1,000 cycles
                </li>
                <li style={liStyle2}>
                  Thermal Decomposition Temperature (@ 100% SoC) : 250˚ C
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <TextPic title={title3} content={content3} img={techImg3} />
    </div>
  );
}

export default Technology;
