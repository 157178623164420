import React from "react";
import { Container } from "react-bootstrap";

function News3() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        May 23, 2023
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        AABC Europe – June 19-22nd, Germany
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        The Stratus Materials team heads to Germany next month to attend the{" "}
        <a
          href="https://www.advancedautobat.com/europe"
          target="_blank"
          rel="noopener noreferrer"
        >
          Advanced Automotive Battery Conference
        </a>{" "}
        (AABC) Europe, a premier automotive battery event, bringing together the
        ‘who’s who’ of global battery technologists and key suppliers
        highlighting developing trends, breakthrough technologies, and future
        outlooks. As investment in vehicle electrification and eMobility
        continues to rapidly increase, next generation battery materials for
        electric vehicles are more important than ever. Stratus looks forward to
        connecting with industry partners and customers during this exciting
        week. Please{" "}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{" "}
        if you would like to schedule a time to connect in Mainz.
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News3;
