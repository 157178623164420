import React from 'react'

function SubImg({img, width}) {
  return (
    <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
    <img
      src={img}
      alt="img"
      style={{ width: width, height: "auto" }}
    />
  </div>
  )
}

export default SubImg