import React from "react";
import SubText from "./SubText";
import SubImg from "./SubImg";

function PicText({ title, content, img, width = "100%" }) {
  return (
    <div>
      <div
        className="container my-5"
        style={{ backgroundColor: "#EDEEF0", padding: "30px" }}
      >
        <div className="row">
          <SubImg img={img} width={width} />
          <SubText title={title} content={content} />
        </div>
      </div>
    </div>
  );
}

export default PicText;
