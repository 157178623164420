import React from 'react';

const SeparationBar = ({ color = '#003561', width = '100%', height = '25px' }) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: color,
      }}
    />
  );
};

export default SeparationBar;