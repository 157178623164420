import React from "react";
import { Row, Col } from "react-bootstrap";
import SeparationBar from "./SeparationBar";

function PageHeader({ PageTitle, SubTitle, SubContent, BackgroundImage }) {
  const titleStyle = {
    fontFamily: "'Montserrat",
  };

  return (
    <>
      <header
        className=" d-flex align-items-center text-light shadow"
        style={{
          height: "55vh",
          backgroundImage: `linear-gradient(rgba(0, 50, 100, 0.5), rgba(0, 50, 100, 0.5)), url(${BackgroundImage})`,
        }}
      >
        <div className="container">
          <Row className="mt-5">
            <Col sm={12} md={10} lg={7}>
              <h1
                className="text-white display-5 fw-bold lh-sm"
                style={titleStyle}
              >
                {PageTitle}
              </h1>
            </Col>
          </Row>
        </div>
      </header>
      <br />
      <div className="container">
        <Row>
          <Col md={12} style={{marginTop: "1rem"}}>
            {/* <br /> */}
            <h2
              style={{
                color: "#003561",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "800",
              }}
            >
              {SubTitle}
            </h2>

            <p
              className="display-6 fw lh-sm"
              style={{
                color: "#003561",
                fontSize: "1.4rem", // Reduced font size for better readability on small screens
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              {SubContent}
            </p>

            {/* <p
              style={{
                color: "#003561",
                fontSize: "1.5rem",
                fontFamily: "sans-serif",
              }}
            >
              {SubContent}
            </p> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PageHeader;
