import React from "react";
import { Container } from "react-bootstrap";

function News5() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        November 8, 2023
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials Announces Updates for its Next-Generation LXMO™
        Cathode Materials
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        PITTSBURGH, November 8, 2023 /BusinessWire/ — Stratus Materials Inc., a
        company focused on the development and commercialization of advanced
        cathode active materials (CAMs) for lithium-ion batteries, today
        announced a set of important updates on the performance and value of its
        next generation LXMOTM CAMs, its sampling efforts with customers and
        partners in the electric vehicle (EV) supply chain, and its future
        scaling plans.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Earlier this year, the company emerged from stealth and announced it was
        producing its proprietary LXMO cathode active material at an early
        pilot-scale. Since then, the team has made significant progress against
        its goals, with new milestones including:
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>LXMO Performance and Value</strong> – The company released a new
        white paper titled, “Modified Lithium-Rich/Manganese Rich Cathode Active
        Materials at Stratus Materials: The Performance and Benefits of LXMOTM”
        that provides a quantitative summary of the unique and valuable
        combination of performance, safety, and cost that LXMO CAMs can deliver
        to the lithium-ion battery industry. This document shows how LXMO, when
        implemented in traditional full cells, can deliver battery performance
        that meets or exceeds cells containing high-nickel NMC CAMs while
        providing the high safety and low cost per unit energy of LFP CAMs. This
        combination of attributes is particularly attractive to and enabling for
        the electric vehicle industry.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>LXMO Sampling</strong> – In February, Stratus began sampling its
        LXMO materials to a set of potential customers and partners in the EV
        industry. These initial sampling efforts have validated the company’s
        internal testing results and will be expanding in the coming quarters.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>LXMO Scaling</strong> – Stratus is currently producing its LXMO
        CAMs at a rate of over one ton per year on its Pre-Pilot line in
        Pittsburgh. To increase production capacity and to demonstrate that LXMO
        can be produced effectively at higher volumes, the company has begun
        work on a 30 ton per year Pilot line that will be operational in
        mid-2024. This new Pilot line will allow Stratus to expand the scope of
        its testing and sampling efforts which will include deploying its
        materials into large-scale battery packs and electric vehicles.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Jay Whitacre, CEO & CTO of Stratus Materials, said: “The pace of
        progress at Stratus has been exceptional, and we are excited about this
        next step towards full commercialization. Our next-generation CAM
        materials will have a dramatic and positive impact on the EV industry in
        the coming years and we look forward to continuing this momentum.”
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Mr. Whitacre will be speaking at the Advanced Automotive Battery
        Conference (AABC) in San Diego, CA next month. Please contact the
        company at{" "}
        <a href="mailto:info@stratusmaterials.com">info@stratusmaterials.com</a>{" "}
        for more information or to inquire about a meeting.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <strong>ABOUT STRATUS MATERIALS</strong>
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        <a
          href="https://www.stratusmaterials.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stratus Materials
        </a>{" "}
        is a U.S. based company focused on the development and commercialization
        of next-generation cathode active materials for lithium-ion batteries.
        Our mission is to disrupt the CAM supply chain by offering materials
        with a game-changing combination of performance, safety, and cost.
        Stratus is primarily focused on cathode solutions for light- and
        medium-duty electric vehicles and other applications with similar
        requirements.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Media Contact:
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Robyn Kennedy DeSocio
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Email: marketing@stratusmaterials.com
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News5;
