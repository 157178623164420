import React from "react";
import { Container } from "react-bootstrap";
import BatterySeminarImg from "./International-Battery-Seminar.png";

function News1() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        February 7, 2022
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials Heads to International Battery Seminar
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        March 20-23, 2023 Orlando, Florida The Stratus Materials team heads to
        The International Battery Seminar 1,500 battery technologists from key
        OEMs, leading cell manufacturers, and the entire advanced battery
        ecosystem participated last year.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <img
          src={BatterySeminarImg}
          alt="BatterySeminar"
          style={{ width: "90%" }}
        />
      </div>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        If you are interested in connecting with Stratus while at the show,{" "}
        <a href="/contact">schedule an appointment.</a> We look forward to
        seeing you.
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News1;
