import React from "react";
import { Container, Button } from "react-bootstrap";
import AboutHeaderImg from "../assets/about-header.jpg";
// import AboutHeaderImg from "../assets/about-header.png";
import about1Img from "../assets/about-1.jpg";
import about2Img from "../assets/about-2.jpg";
import about3Img from "../assets/about-3.jpg";
import about4Img from "../assets/about-4.jpg";

import PageHeader from "../components/PageHeader";
import PicText from "../components/PicText";
import TextPic from "../components/TextPic";

const PageTitle = "Mission-Driven Innovation & Scaling";
const SubTitle =
  "Our mission is to provide innovative cathode materials solutions that \
  dramatically improve lithium-ion batteries and enable global electrification.";
const SubContent = `Cathode active materials are the single biggest lever for determining the cost\
   and energy density of lithium-ion batteries.  For years, the lithium-ion battery\
    industry has been seeking the performance and cost advantages of lithium-rich, \
    manganese-rich cathode chemistries (LMR), but these cathodes have faced persistent\
     challenges with stability when cycling. Utilizing proprietary formulations and \
     production processes, the Stratus Materials team has developed manganese-rich, \
     zero-cobalt cathode materials that deliver outstanding \
     functionality, energy density, cycling stability, and low cost, overcoming the \
     challenges that have prevented the commercialization LMR systems to date. \
     Stratus has branded its class of advanced materials as LXMO™.`;

const title1 = "No Tradeoffs";
const title2 = "Origin";
const title3 = "Mass Production";
const content1 =
  "For today’s EV companies, the attributes of current commercial CAMs\
 often require significant tradeoffs to be made between performance, range, safety,\
  and cost. Stratus’s LXMO provides EV OEMs and their customers with a superior\
   cathode solution <u>without</u> tradeoffs.";
const content2 =
  "The company was founded in early 2022 to commercialize a set of\
 highly promising technical innovations by a team led by Dr. Jay Whitacre, a Carnegie\
  Mellon University professor and entrepreneur. Dr. Whitacre is a world-class battery\
   expert who has pursued LMR materials for almost two decades. The foundational work\
    for Stratus occurred at two prior incubator companies named 33 Tech and Scaled\
     Ionics.";
const content3 =
  "Stratus’s goal is to make LXMO a critical element in building future\
 domestic CAM supply chains for advanced battery and electric vehicle production in the\
  North American and European regions. Stratus will be partnering with industry leaders\
   to establish high-volume LXMO production in North America, Europe, and Asia in the\
    coming years.";

const color = "#EDEEF0";

function About() {
  return (
    <div className="about-page">
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={AboutHeaderImg}
      />
      <br />
      <div style={{ backgroundColor: "#EDEEF0" }}>
        <Container style={{ backgroundColor: "#EDEEF0", padding: "30px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <h3
              style={{
                color: "#003561",
                marginBottom: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "800",
              }}
            >
              STRATUS WHITE PAPER: “The Performance and Benefits of LXMO”
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              size="lg"
              href="/whitepaper"
              style={{
                backgroundColor: "#00ade6",
                borderColor: "#00ade6",
                borderRadius: "0",
                width: "230px",
              }}
            >
              <span
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                }}
              >
                DOWNLOAD
              </span>
            </Button>
          </div>
        </Container>
      </div>

      <div
        className="container my-5"
        style={{ backgroundColor: "#EDEEF0", padding: "30px" }}
      >
        <div className="row">
          <div
            className="col-lg-6 col-md-12 d-flex flex-column justify-content-center"
            style={{ height: "auto" }} // Make height auto for smaller screens
          >
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "2rem", // Default for large screens
                fontFamily: "'Montserrat'",
                fontWeight: "800",
                marginBottom: "1.5rem", // Reduced margin for better spacing
                marginTop: "1rem",
              }}
            >
              {title1}
            </p>
            <p
              className="display-6 fw lh-sm mx-3"
              style={{
                color: "#003561",
                fontSize: "1.4rem", // Reduced font size for better readability on small screens
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              For today’s EV companies, the attributes of current commercial
              CAMs often require significant tradeoffs to be made between
              performance, range, safety, and cost. Stratus’s LXMO provides EV
              OEMs and their customers with a superior cathode solution{" "}
              <u>without</u> tradeoffs.
            </p>
          </div>

          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img
              src={about1Img}
              alt="img"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#003561" }}>
        <br /> <br />
        <Container>
          <img
            src={about2Img}
            alt="Logo"
            style={{ width: "100%", border: "None" }}
          />
          <p
            className=" display-6 fw lh-sm"
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontFamily: "Montserrat",
              marginTop: "2rem",
              fontWeight: "bold",
            }}
          >
            LXMO delivers a unique and highly advantaged combination of density,
            cost, and safety vs. industry leaders
          </p>
          <p
            className=" display-6 fw lh-sm"
            style={{
              color: "white",
              fontSize: "1.4rem",
              fontFamily: "Montserrat",
              fontWeight: "400",
            }}
          >
            For today’s EV companies, the attributes of current commercial CAMs
            often require significant tradeoffs to be made between performance,
            range, safety, and cost. Stratus’s LXMO provides EV OEMs and their
            customers with a superior cathode solution without tradeoffs.
          </p>
        </Container>
        <br />
      </div>
      <TextPic title={title2} content={content2} img={about3Img} width="100%" />
      <PicText title={title3} content={content3} img={about4Img} width="90%" />
    </div>
  );
}

export default About;
