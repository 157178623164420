import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Home from "./pages/Home";
import News from "./pages/News";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Team from "./pages/Team";
import Technology from "./pages/Technology";
import Contact from "./pages/Contact";
import Markets from "./pages/Markets";
import WhitePaper from "./pages/WhitePaper";
import StratusLogo from "./components/logo/LogoStratus";
import SeparationBar from "./components/SeparationBar";
import TopMenu from "./components/TopMenu";
import News1 from "./pages/NewsItem/News1";
import News2 from "./pages/NewsItem/News2";
import News3 from "./pages/NewsItem/News3";
import News4 from "./pages/NewsItem/News4";
import News5 from "./pages/NewsItem/News5";
import News6 from "./pages/NewsItem/News6";

function App() {
  return (
    <div>
      <TopMenu />

      <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/1" element={<News1 />} />
          <Route path="/news/2" element={<News2 />} />
          <Route path="/news/3" element={<News3 />} />
          <Route path="/news/4" element={<News4 />} />
          <Route path="/news/5" element={<News5 />} />
          <Route path="/news/6" element={<News6 />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/whitepaper" element={<WhitePaper />} />
        </Routes>
      </div>

      <footer
        style={{ backgroundColor: "#003561", color: "white" }}
     
      >
        <SeparationBar color="#00ade6" height="20px"/>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} className="text-center my-3">
              <StratusLogo fontColor="white" />
            </Col>
            <br />
            <Col xs={12} className="text-center my-3">
              <p className="m-0">
                © COPYRIGHT 2024 STRATUS MATERIALS INC | ALL RIGHTS RESERVED
              </p>
            </Col>
          </Row>
        </Container>
      
      </footer>
    </div>
  );
}

export default App;
