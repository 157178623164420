import React from "react";

function LogoSafety({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{ display: "block", maxWidth: "122px", margin: "0 auto" }}
    >
      <path
        strokeLinecap="square"
        strokeLinejoin="round"
        d="m21.25 22.9 2.2 2.2 4.4-4.4m-13.2 12.1L24 40.5l9.35-7.7m4.4-16.5v-4.84C30.6 10.25 24 7.5 24 7.5s-6.6 2.75-13.75 3.96v9.9m5.94 5.94c0 3.93-5.94 3.93-5.94 0s5.94-3.93 5.94 0Zm18.59 2.97c-3.93 0-3.93-5.94 0-5.94 1.64 0 3.93 5.94 0 5.94Zm-3.08-1.98H16.3m23.71.66c1.31-.42 1.28-.33 2.57-1.38a.45.45 0 0 0 .23-.22c.79-1.92.43-2.38-.46-6.32-4.6-1.22-7.15-2.66-12.03-3.12-6.66-.63-8.92.31-14.31 3.05-1.11.57-2.39.55-4.36.85-2.77.42-7.79 1.87-6.82 4.5l.64 1.32 2.56.66"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
    </svg>
  );
}

export default LogoSafety;
