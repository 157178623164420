import React from "react";

function LogoNoCo({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{ display: "block", maxWidth: "122px", margin: "0 auto" }}
    >
      <path
        d="M40.41 9.24c0-.97-.8-1.75-1.79-1.74l-29.35.09c-.98 0-1.78.79-1.77 1.76l.09 29.38c0 .97.8 1.77 1.78 1.77h29.35c.98 0 1.78-.8 1.77-1.77L40.4 9.25Z"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
      <path
        d="M26.06 28.18a4.46 4.46 0 1 0 8.93 0 4.46 4.46 0 0 0-8.93 0Zm10.31-16.56L11.34 36.65M24 18.43a7.54 7.54 0 1 0 0 13.35"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
    </svg>
  );
}

export default LogoNoCo;
