import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import LogoNoCo from "./logo/LogoNoCo";
import LogoEconomic from "./logo/LogoEconomic";
import LogoHiPerform from "./logo/LogoHiPerform";
import LogoSafety from "./logo/LogoSafety";

const FeatureRow = () => {
  const color = "#00ade6";
  return (
    <div style={{ backgroundColor: "white" }}>
      <Container
        className="px-5"
        style={{ backgroundColor: "white" }}
      >
        <Row className="align-items-center text-center">
          <Col xs={12} md={6} lg={8}>
            <Row>
              <Col xs={6} md={3}>
                <LogoNoCo color={color} />
                <p
                  style={{
                    fontSize: "1.3rem",
                    color: "#344D76",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                  }}
                >
                  Cobalt Free
                  <br />
                  Cathode
                </p>
              </Col>
              <Col xs={6} md={3}>
                <LogoEconomic color={color} />
                <p
                  style={{
                    fontSize: "1.3rem",
                    color: "#344D76",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                  }}
                >
                  Cost
                  <br />
                  Competitive
                </p>
              </Col>
              <Col xs={6} md={3}>
                <LogoSafety color={color} />
                <p
                  style={{
                    fontSize: "1.3rem",
                    color: "#344D76",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                  }}
                >
                  Safe/Stable
                  <br />
                  for EVs
                </p>
              </Col>
              <Col xs={6} md={3}>
                <LogoHiPerform color={color} />
                <p
                  style={{
                    fontSize: "1.3rem",
                    color: "#344D76",
                    fontFamily: "Montserrat",
                    fontWeight: "800",
                  }}
                >
                  High
                  <br />
                  Performance
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={4} className="text-center">
            <Button
              size="lg"
              href="/technology"
              style={{
                backgroundColor: "#00ade6",
                borderColor: "#00ade6",
                borderRadius: "0",
                width: "230px",
              }}
            >
              <span
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                }}
              >
                LEARN MORE
              </span>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeatureRow;
