import React from "react";
import { Row, Col } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import breakThroughImg from "../assets/BreakThroughEnergy.png";
import TeamHeaderImg from "../assets/team-header.JPG";
import dnsImg from "../assets/DNS.png";
import TeamSection from "../components/TeamSection";

const PageTitle = "Structured for Success";
const SubTitle = "Management, Advisors & Investors";
const SubContent =
  "Driven by a passion for making impactful contributions to the world,\
   Team Stratus consists of world-class experts in the innovation,\
    development, deployment, financing, and scaling of lithium-ion\
     batteries and active materials. ";

function Team() {
  return (
    <div>
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={TeamHeaderImg}
      />
      <br />
      <TeamSection />
      <br />
      <div className="container">
        <Row>
          <Col md={12}>
            <h1
              style={{
                color: "#003561",
                marginBottom: "1rem",
                marginTop: "1rem",
                fontFamily: "Montserrat",
                fontWeight: "800",
              }}
            >
              Investors
            </h1>
            <p
              style={{
                color: "#003561",
                fontSize: "1.4rem",
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              Stratus is backed by a world-class syndicate of investors.
            </p>
          </Col>
        </Row>
      </div>
      <br />

      <div className="container ">
        <Row style={{ marginBottom: "10px" }}>
          <Col md={6} sm={12}>
            <a
              href="https://www.breakthroughenergy.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={breakThroughImg}
                alt="about img"
                style={{
                  width: "80%",
                  height: "100%",
                  border: "none",
                  padding: "10px",
                }}
              />
            </a>
          </Col>
          <Col md={6} sm={12}>
            <a
              href="https://www.dnscap.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={dnsImg}
                alt="about img"
                style={{
                  width: "80%",
                  height: "100%",
                  border: "none",
                  padding: "10px",
                }}
              />
            </a>
          </Col>
        </Row>
      </div>
      <br />
    </div>
  );
}

export default Team;
