import React from 'react'

function SubText({ title, content, height = "500px" }) {
  return (
    <div
    className="col-lg-6 col-md-12 d-flex flex-column justify-content-center"
    style={{ height: "auto" }} // Make height auto for smaller screens
  >
    <p
      className="display-6 fw lh-sm mx-3"
      style={{
        color: "#003561",
        fontSize: "2rem", // Default for large screens
        fontFamily: "Montserrat",
        fontWeight: "800",
        marginBottom: "1.5rem", // Reduced margin for better spacing
        marginTop: "1rem",
      }}
    >
      {title}
    </p>
    <p
      className="display-6 fw lh-sm mx-3"
      style={{
        color: "#003561",
        fontSize: "1.4rem", // Reduced font size for better readability on small screens
        fontFamily: "Montserrat",
        fontWeight: "400",
      }}
    >
      {content}
    </p>
  </div>
  )
}

export default SubText