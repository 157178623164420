import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Button, Form, Alert, Spinner } from "react-bootstrap";
import ContactHeaderImg from "../assets/contact-header.jpg";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://stratusmaterials.com/api/send-email",
        {
          ...formData,
          formtype: "contact",
        }
      );
      console.log("Form submitted successfully", response.data);
      setSuccessMessage("Form submitted successfully!");
      setErrorMessage("");
      setFormData({
        name: "",
        email: "",
        company: "",
        phone: "",
        message: "",
      });
      setTimeout(() => setSuccessMessage(""), 2000); // Clear success message after 2 seconds
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setErrorMessage(
        "There was an error submitting the form. Please try again or send your info to info@stratusmaterials.com"
      );
      setSuccessMessage("");
      setTimeout(() => setErrorMessage(""), 5000); // Clear error message after 5 seconds
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <header
        className="d-flex align-items-center text-light shadow"
        style={{
          height: "20vh",
          backgroundColor: "#003561",
        }}
      >
        <div className="container">
          <Row className="mt-5">
            <Col sm={12} md={8} lg={6}>
              <h1
                className="text-white fw-bold lh-sm"
                style={{
                  fontSize: "3rem", // Reduced font size for better readability on small screens
                  fontFamily: "Montserrat",
                }}
              >
                Contact
              </h1>
            </Col>
          </Row>
        </div>
      </header>
      <br />
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column align-items-left">
            <p style={{ fontSize: "1.4rem", fontFamily: "Montserrat" }}>
              <strong>Address:</strong> 6901 Lynn Way, Suite 320 Pittsburgh, PA
              15208
            </p>
            <p style={{ fontSize: "1.4rem", fontFamily: "Montserrat" }}>
              <strong>Email: </strong>
              <a href="mailto:info@stratusmaterials.com">
                info@stratusmaterials.com
              </a>
            </p>
            <iframe
              loading="lazy"
              src="https://maps.google.com/maps?q=6901%20Lynn%20Way%2C%20Suite%20320%2C%20Pittsburgh%2C%20PA%2015208&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
              title="6901 Lynn Way, Suite 320, Pittsburgh, PA 15208"
              aria-label="6901 Lynn Way, Suite 320, Pittsburgh, PA 15208"
              style={{ width: "100%", height: "400px" }}
            ></iframe>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <Form style={{ width: "350px" }} onSubmit={handleSubmit}>
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form.Group className="row mb-6 my-2">
                <Form.Label htmlFor="name" style={{ fontFamily: "Montserrat" }}>
                  Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="row mb-6 my-2">
                <Form.Label htmlFor="email" style={{ fontFamily: "Montserrat" }}>
                  Email
                </Form.Label>
                <Form.Control
                  type="text"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="row mb-6 my-2">
                <Form.Label htmlFor="company" style={{ fontFamily: "Montserrat" }}>
                  Company
                </Form.Label>
                <Form.Control
                  type="text"
                  id="company"
                  value={formData.company}
                  onChange={handleChange}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="row mb-6 my-2">
                <Form.Label htmlFor="phone" style={{ fontFamily: "Montserrat" }}>
                  Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="row mb-6 my-2">
                <Form.Label htmlFor="message" style={{ fontFamily: "Montserrat" }}>
                  Message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  required={true}
                />
              </Form.Group>

              <Button
                type="submit"
                style={{
                  backgroundColor: "#344D76",
                  borderColor: "#344D76",
                  borderRadius: "0",
                  marginTop: "15px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                    }}
                  >
                    SUBMIT
                  </span>
                )}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
