import React from "react";
import { Container } from "react-bootstrap";

function News4() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        November 7, 2023
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials to AABC, San Diego, Dec 11-14, 2023{" "}
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Mark your calendars as Stratus Materials heads to{" "}
        <a
          href="https://www.advancedautobat.com/us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Advanced Automotive Battery Conference, San Diego
        </a>{" "}
        Dec 11 – 14th 2023. On December 12th, Stratus’ own CEO, Jay Whitacre is
        leading a talk titled ‘Next-Generation Cobalt-Free Manganese-Rich
        Cathodes for Long-Range EVs.’ Don’t miss this year’s automotive battery
        conference to uncover underlying technical issues impacting the pace and
        path of vehicle electrification worldwide. For more information or to
        schedule an appointment with us during the event, reach out at
        info@stratusmaterials.com
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News4;
