import React from "react";

function LogoHiPerform({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{ display: "block", maxWidth: "122px", margin: "0 auto" }}
    >
      <path
        d="m36.1 20.55-2.54 1.05m-4.51-8.11L28 16.03m-.95 6.21 6.14-5.84m-9.13-1.16v-2.75m-3.93 3.53-1.05-2.54m-7.05 7.05 2.54 1.05m2.23-3.34-1.94-1.94M27.89 25.11a3.89 3.89 0 1 1-7.78 0 3.89 3.89 0 0 1 7.78 0ZM16.78 34.54h14.43"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
      <path
        d="M24 8.46C7.01 8.46.98 31.05 15.7 39.54h16.6C47.02 31.05 40.99 8.46 24 8.46Z"
        style={{ fill: "none", stroke: color, strokeWidth: "1.6px" }}
      />
    </svg>
  );
}

export default LogoHiPerform;
